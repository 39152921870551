import axios from "axios";
import { baseUrl } from "../config";
require("dotenv").config();

export const getUsers = (token) => {
	const res = axios.get(baseUrl + "/admin/allUsers", {
		headers: { "x-auth-token": token },
	});
	return res;
};

export const getRoles = (token) => {
	const res = axios.get(baseUrl + "/admin/roles", {
		headers: { "x-auth-token": token },
	});
	return res;
};

export const deleteUser = (token, userID) => {
	const res = axios.delete(baseUrl + "/admin/user", {
		data: { userID: userID },
		headers: { "x-auth-token": token },
	});
	return res;
};

export const getUserByID = (token, user) => {
	const res = axios.get(baseUrl + "/admin/user/" + user, {
		headers: { "x-auth-token": token },
	});
	return res;
};

export const updateUser = (token, user) => {
	const res = axios.put(baseUrl + "/admin/user", user, {
		headers: { "x-auth-token": token },
	});
	return res;
};

export const updatePassword = (token, emails) => {
	const res = axios.put(baseUrl + "/admin/updatepassword", emails, {
		headers: { "x-auth-token": token },
	});
	return res;
};

export const editRole = (token, payload) => {
	const res = axios.put(baseUrl + "/admin/role", payload, {
		headers: { "x-auth-token": token },
	});
	return res;
};

export const registerUser = (token, user) => {
	const res = axios.post(baseUrl + "/admin/user", user, {
		headers: { "x-auth-token": token },
	});
	return res;
};
