import axios from "axios";
import { baseUrl } from "../config";
var jwt = require("jsonwebtoken");
require("dotenv").config();

export const tokenIsValid = (token) => {
	const res = axios.post(baseUrl + "/auth/tokenIsValid", null, {
		headers: { "x-auth-token": token },
	});
	return res;
};

export const getRole = () => {
	const token = localStorage.getItem("BoxterToken");
	let role = jwt.decode(token).role;

	return role;
};

export const getUser = (token) => {
	const res = axios.get(baseUrl + "/users/user", {
		headers: { "x-auth-token": token },
	});
	return res;
};

export const registerUser = (user) => {
	try {
		const res = axios.post(baseUrl + "/users", user);
		return res;
	} catch (error) {
		return error.response;
	}
};

export const loginUser = (user) => {
	try {
		const res = axios.post(baseUrl + "/auth", user);
		return res;
	} catch (error) {
		return error;
	}
};

export const getTokenExpire = () => {
	const token = localStorage.getItem("BoxterToken");
	let expire = jwt.decode(token).exp * 1000;
	let now = new Date();
	expire = expire - now.getTime();
	return expire;
};
