import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { CloseButton, Fade } from "../components/common/Toast";
import {auth} from "../endpoints"

import AuthContext from "../context/Auth";
import DashboardLayout from "./DashboardLayout";
import ErrorLayout from "./ErrorLayout";

import loadable from "@loadable/component";
const AuthBasicLayout = loadable(() => import("./AuthBasicLayout"));

const Layout = () => {
  const [userData, setUserData] = useState({
  });
  const checkLoggedIn = async () => {
      let token = localStorage.getItem("BoxterToken");
      if(token === null){
        localStorage.setItem("BoxterToken", "");
        token = "";
      }
      const tokenRes = await auth.tokenIsValid(token);
      if(tokenRes.status === 200){
        const userRes = await auth.getUser(token);
        setUserData({
          token,
          user: userRes.data,
        });
      } else {
        localStorage.setItem("BoxterToken", "");
        setUserData({
          token: undefined,
          user: undefined
        });
      }
    }
  useEffect(() => {
    checkLoggedIn();
    AuthBasicLayout.preload();
  }, []);

  return (
      <Router fallback={<span />}>
        <AuthContext.Provider value={{userData, setUserData}}>
          {localStorage.getItem('BoxterToken') ? (
            <Switch>
              <Route path="/errors" component={ErrorLayout} />
              <Route component={DashboardLayout} />
            </Switch>
          ) : (
            <Switch>
              <Route component={AuthBasicLayout} />
            </Switch>
          )
        }   
          <ToastContainer
            transition={Fade}
            closeButton={<CloseButton />}
            position={toast.POSITION.BOTTOM_LEFT}
          />
        </AuthContext.Provider>
      </Router>
  );
};

export default Layout;
