const auth = require("./auth");
const admin = require("./admin");
const tags = require("./tags");
const boxService = require("./box");

// Get all utils and export them to require them with greater ease.
module.exports = {
  auth,
  admin,
	tags,
  boxService
};
