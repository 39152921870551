import React, { useContext } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import AppContext from "../../context/Context";
import boxterLogoLight from "../../assets/img/logos/Boxter-Full-Light-Medium.png";
import boxterLogoLightRetina from "../../assets/img/logos/Boxter-Full-Light-Medium@2x.png";
import boxterLogoDark from "../../assets/img/logos/Boxter-Full-Dark-Medium.png";
import boxterLogoDarkRetina from "../../assets/img/logos/Boxter-Full-Dark-Medium@2x.png";

const Logo = ({ at, width, className, ...rest }) => {
  const { isDark } = useContext(AppContext);
  let boxterLogo = boxterLogoLight;
  let boxterLogoRetina = boxterLogoLightRetina;
  if (isDark) {
    boxterLogo = boxterLogoLight;
    boxterLogoRetina = boxterLogoLightRetina;
  } else {
    boxterLogo = boxterLogoDark;
    boxterLogoRetina = boxterLogoDarkRetina;
  }

  return (
    <Link
      to="/"
      className={classNames(
        "text-decoration-none",
        { "navbar-brand text-left": at === "navbar-vertical" },
        { "navbar-brand text-left": at === "navbar-top" }
      )}
      {...rest}
    >
      <div
        className={classNames(
          "d-flex",
          {
            "align-items-center py-3": at === "navbar-vertical",
            "align-items-center": at === "navbar-top",
            "flex-center font-weight-extra-bold fs-5 mb-4": at === "auth",
          },
          className
        )}
      >
        <img
          src={boxterLogo}
          width="149"
          alt="Boxter Logo"
          className="mb-1"
          srcSet={`${boxterLogo} 149w, ${boxterLogoRetina} 298w`}
        />
      </div>
    </Link>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(["navbar-vertical", "navbar-top", "auth"]),
  width: PropTypes.number,
  className: PropTypes.string,
};

Logo.defaultProps = { at: "auth", width: 58 };

export default Logo;
