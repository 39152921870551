import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';

const Error403 = () => (
  <Card className="text-center">
    <CardBody className="p-5">
      <div className="display-1 text-200 fs-error">403</div>
      <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">
        Du har ikke adgang til denne side.
      </p>
      <hr />
      <p>
        Sørg for at adressen er korrekt og at siden ikke har flyttet sig. Hvis du mener at dette er en fejl
        <a href="mailto:info@exmaple.com" className="ml-1">
          Skriv til os
        </a>
        .
      </p>
      <Link className="btn btn-primary btn-sm mt-3" to="/">
        <FontAwesomeIcon icon="home" className="mr-2" />
        Forside
      </Link>
    </CardBody>
  </Card>
);

export default Error403;
