import axios from "axios";
import { baseUrl } from "../config";
require("dotenv").config();

export const getTags = (token) => {
	const res = axios.get(baseUrl + "/tags", {
		headers: { "x-auth-token": token },
	});
	return res;
};

export const deleteTagByID = (token, tagID) => {
	const res = axios.delete(baseUrl + "/tags/" + tagID, {
		headers: { "x-auth-token": token },
	});
	return res;
};

export const deleteTags = (token, tagList) => {
	const res = axios.delete(baseUrl + "/tags", {
		data: tagList,
		headers: { "x-auth-token": token },
	});
	return res;
};

export const updateTag = (token, tagID, tagType) => {
	const res = axios.put(
		baseUrl + "/tags/" + tagID,
		{ tagType },
		{
			headers: { "x-auth-token": token },
		}
	);
	return res;
};

export const createTag = (token, tag) => {
	const res = axios.post(baseUrl + "/tags", tag, {
		headers: { "x-auth-token": token },
	});
	return res;
};

//TAGTYPES

export const getTagTypes = (token) => {
	const res = axios.get(baseUrl + "/tags/types", {
		headers: { "x-auth-token": token },
	});
	return res;
};

export const createTagType = (token, payload) => {
	const res = axios.post(baseUrl + "/tags/types", payload, {
		headers: { "x-auth-token": token },
	});
	return res;
};

export const updateTagType = (token, tagType, payload) => {
	const res = axios.put(baseUrl + "/tags/types/" + tagType, payload, {
		headers: { "x-auth-token": token },
	});
	return res;
};

export const deleteTagType = (token, tagType) => {
	const res = axios.delete(baseUrl + "/tags/types/" + tagType, {
		headers: { "x-auth-token": token },
	});
	return res;
};
