import axios from "axios";
import { baseUrl } from "../config";
require("dotenv").config();

export const getBoxes = (token) => {
	const res = axios.get(baseUrl + "/box", {
		headers: { "x-auth-token": token },
	});
	return res;
};

export const createNewBox = (token, newBox) => {
	const res = axios.post(baseUrl + "/box", newBox, {
		headers: { "x-auth-token": token },
	});
	return res;
};

export const deleteBox = (token, boxID) => {
	const res = axios.delete(baseUrl + "/box/" + boxID, {
		headers: { "x-auth-token": token },
	});
	return res;
};

export const getBoxInfo = (token, boxID) => {
	const res = axios.get(baseUrl + "/box/" + boxID, {
		headers: { "x-auth-token": token },
	});
	return res;
};

export const updateBox = (token, boxID, updatetBox) => {
	const res = axios.put(baseUrl + "/box/" + boxID, updatetBox, {
		headers: { "x-auth-token": token },
	});
	return res;
};

export const getBoxTypes = (token) => {
	const res = axios.get(baseUrl + "/box/boxTypes", {
		headers: { "x-auth-token": token },
	});
	return res;
};

export const createBoxType = (token, payload) => {
	const res = axios.post(baseUrl + "/box/boxTypes", payload, {
		headers: { "x-auth-token": token },
	});
	return res;
};

export const deleteBoxType = (token, payload) => {
	const res = axios.delete(baseUrl + "/box/boxTypes", {
		data: payload,
		headers: { "x-auth-token": token },
	});
	return res;
};

export const updateBoxType = (token, payload) => {
	const res = axios.put(baseUrl + "/box/boxTypes", payload, {
		headers: { "x-auth-token": token },
	});
	return res;
};
